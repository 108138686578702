<template>
  <!--<div class="popup_layer main">
    <div class="popup_header">
      <button type="button" class="bt_closepop" @click.stop="$emit('close')">닫기</button>
    </div>
    <div class="popup_contents">
      <div class="main_html" v-html-custom="html">
      </div>
    </div>
    <div class="btn">
      <button type="button" class="bt_white_xs" @click.stop="closeAndDisablePopup()">오늘 하루 보지 않기</button>
    </div>
  </div>-->

<div class="lyrBg_dim" id="lyrBg_dim_lyr_notice" style="z-index: 1050;" v-if="popupList.length > 0">
<div class="layer_dim is-layer" style="display: block; z-index: 1100;">	
  <div style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; z-index: -10; opacity: 0.5; background-color: rgb(0, 0, 0); "></div>
	<div class="layer_box noticeBn">
    	<div>
			<div class="bnr" id="noticeBn" :style="`aspect-ratio: ${popupWidth} / ${popupHeight};`">
      <!-- :style="`height: ${popupHeight}px`" -->
      <swiper :options="popupList.length === 1 ? swiperOptions2 : swiperOptions">
                <swiper-slide
                  v-for="(popups, index) in popupList"
                  :key="`popups_${index}`"
                >
                <ul class="swiper-wrapper">
                  <li class="swiper-slide">
                    <p v-html-custom="popups.content"></p>
                  </li>
                </ul>
                </swiper-slide>
                  <div class="swiper-pagination1" slot="pagination" v-if="popupList.length > 1"></div>
      </swiper>
			</div>
            <div class="ntcBtn">
                <a href="#" @click.close="closeAndDisablePopup()" class="nomore">오늘 하루 보지 않기</a>
		            <a href="#" @click.close="closePopup()" class="close">닫기</a>
            </div>
		</div>
	</div>
</div>
</div>
</template>

<script>
export default {
  props: {
    html: String,
    popupList: Array,
    popupHeight: Number,
    popupWidth: Number,
  },
    data: () => ({
    swiperOptions: {
      direction: 'horizontal',
      slidesPerView: 1,
      loopedSlides:1,
      loop: true,
      speed : 1000,
      autoHeight: true,
      pagination: {
            el: '.swiper-pagination1',
            //type: 'custom',
            type: 'fraction',
        },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    },
    swiperOptions2: {
      direction: 'horizontal',
      slidesPerView: 1,
      loop: false,
      speed : 1000,
      allowTouchMove: false,
      simulateTouch: false,
      autoHeight: true,
    },
    filteredPopupList: [],
    swiperOption: null
  }),

  methods: {
    closeAndDisablePopup() {
      //this.$attrs.disablePopup();
      this.$emit("disablePopup");
      this.$emit("closePopup");
      //this.$emit("close");
    },
    closePopup(){
      this.$emit("closePopup");
      //this.$emit("close");
    },
  },
  mounted() {
   
    document.body.style.overflow = 'hidden'
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  },

}
</script>
<style lang="scss" scoped>
/*.popup_layer {
  width: inherit;
  .popup_contents {
    padding: 0 20px 20px;
    max-height: 500px;
    overflow-y: auto;

    /deep/ img {
      max-width: 100%;
    }
  }
  .btn {
    padding: 0 20px 20px;
  }
  img {
    max-width: 100%;
  }
}*/
.noticeBn{width: inherit; height:100%;}
.noticeBn .bnr{overflow:hidden;position:relative;}
.noticeBn .bnr a{display:block;}
.noticeBn .bnr img{width:100%;height:100%;vertical-align:top;object-fit: cover;}
.noticeBn .bnr {
  &::v-deep img {
    width:100%;height:100%;vertical-align:top;
  }
}
.noticeBn .swiperCtr a{display:block;position:absolute;top:185px;width:11px;height:21px;border:none;padding:0;overflow:hidden;background:transparent;text-indent:100%;z-index:10;}
.noticeBn .swiperCtr a:after{content:"";display:block;position:absolute;width:30px;height:30px;-webkit-transform:rotate(45deg);transform:rotate(45deg);border:1px solid #a5a9b4;}
.noticeBn .swiperPg{position:absolute;bottom:0;width:100%;height:6px;padding-bottom:15px;text-align:center;}
.noticeBn .swiperPg span{display:inline-block;overflow:hidden;height:6px;vertical-align:top;}
.noticeBn .swiperPg a{display:block;float:left;margin:0 3px;width:6px;height:6px;border:none;padding:0;border-radius:50% 50%;background-color:#fff;text-indent:100%;vertical-align:top;opacity:.5;overflow:hidden;}
.noticeBn .swiperPg .swiper-active-switch{opacity:1;}
.noticeBn .ntcBtn{overflow:hidden; background-color: #fff;}
.noticeBn .ntcBtn a{
  height: 50px;
	line-height: 50px;
	font-size: 14px;
  width: 40%;
}
.noticeBn .ntcBtn .nomore{
  float: left;
	color: #a7a7a7;
	margin-left: 16px;
}
.noticeBn .ntcBtn .close{
  color: #20202c;
	float: right;
	margin-right: 16px;
  text-align: right;
}
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {height: 100% !important;}
.swiper-slide p {height: 100%;}
.pagination_wrap {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    height: 10%;
    font-size: 11px;
    font-weight: 400;
    z-index: 999;
}
.swiper-pagination1 {
    z-index: 999;
    position: absolute !important;
    width: 44px;
    font-size: 11px;
    padding: 5px 10px;
    top: 0;
    height: 23px;
    left: calc(100% - 44px);
    background-color: rgba(255, 255, 255, 0.5);
}
</style>
