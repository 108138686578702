<template>
  <section class="theme_wrap spec_para">
    <div class="theme_con" v-for="(banner, bannerIndex) in banners" :key="`banner-${bannerIndex}`">
        <div class="the_thumb spec_img_wrap" style="background-color: black;max-height:800px;">
          <div class="thum_wrap" data-aos="fade-left" data-aos-duration="0" style="background-color: white;">
            <div v-if="banner.bnrVideoUrl">
              <video muted loop autoplay playsinline webkit-playsinline class="top-visual-video" style="height: auto; position: unset;">
                <source :src="banner.bnrVideoUrl" type="video/mp4" />
              </video>
            </div>
            <div v-else class="thum_wrap">
              <img :src="getImageUrlRS(banner.bnrImgPath, 2560)" onerror="this.src='/images/no-img.jpg'" alt="" />
            </div>
          </div>
          <div class="banner_info_wrap">
            <div :id="`special-text-box-anchor-${bannerIndex}`" style="overflow: hidden;">
              <div data-aos="slide-up" :data-aos-anchor="`#special-text-box-anchor-${bannerIndex}`" data-aos-offset="200" data-aos-delay="0" data-aos-duration="1000">
                <div class="bn_txt_wrap">
                  <p class="ttl" v-html-custom="banner.bnrText"></p>
                </div>
                <div class="bn_txt_wrap">
                  <p class="sub" v-show="banner.bnrHtml" v-html-custom="banner.bnrHtml"></p>
                </div>
              </div>
            </div>

            <div class="spec_btn_wrap" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="500" data-aos-duration="500">
              <a :href="getUrl(banner.bnrBtnUrl1)" v-if="banner.bnrBtnCnt >= 1">{{ banner.bnrBtnNm1 }}</a>
              <a :href="getUrl(banner.bnrBtnUrl2)" v-if="banner.bnrBtnCnt >= 2">{{ banner.bnrBtnNm2 }}</a>
              <!-- a :href="getUrl(bannerInfo[bannerIndex].url)" v-for="(btn, idx) in bannerInfo[bannerIndex].btn" :key="btn-`${idx}`">{{ btn }}</a-->
            </div>
          </div>
        </div>

      <div class="spec_wrap" v-if="(banner.goodsList || []).length > 0">
          <div class="spec_info" style="aspect-ratio: 1920/770;">
            <div class="main_bxtit main_df mgb20">
            <strong v-html="banner.goodsTitle"></strong>
            <a :href="banner.bnrLinkUrl" class="view_all_btn">view all</a>
            <!-- <p v-html="banner.bnrHtml"></p> -->
            </div>
            <div class="wrapper">
            <SpecialList :goods-list="banner.goodsList"></SpecialList>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import SpecialList from "@/components/main/desktop/SpecialList.vue";

export default {
  components: {
    SpecialList,
  },
  props: {
    category: {
      type: Object,
      default: () => Array
    },
    callbackSpecialFetchComplete: {
      type: Function,
      default: () => undefined
    }
  },
  data() {
    return{
      // goodsList: [],
      banners: [],
      //goodsCategory:[800900105, 800900106, 800900107],
      //limitBannerLength : 3
    }
  },

  async fetch() {
    await this.fetchBanners();
    if(this.callbackSpecialFetchComplete) {
      this.callbackSpecialFetchComplete();
    }
  },

  methods: {
    async fetchBanners() {
      let banners = await this.$axios.$get(`/api/main/${this.category.categoryNo}/banners`);
      let goodsList = await this.$axios.$get(`/api/main/${this.category.categoryNo}/goods`);
      banners = banners.filter(banner => !banner.goodsId);
      
      if (this.$device.isMobile) {
        banners = banners.filter(banner => {
          return banner.bnrWebMobileGbCd === '00' || banner.bnrWebMobileGbCd === '20'
        })
      } else {
        banners = banners.filter(banner => {
          return banner.bnrWebMobileGbCd === '00' || banner.bnrWebMobileGbCd === '10'
        })
      }

      banners.forEach(banner => {
        banner.goodsList = goodsList.filter(goods => (goods.dispBnrNoList||[]).indexOf(banner.dispBnrNo) > -1 );
      });

      this.banners = banners;
      /*
      if(this.banners && this.banners.length > this.limitBannerLength) {
        this.banners = this.banners.slice(0, this.limitBannerLength);
      }
      */
      // console.log(`/api/main/${this.category.categoryNo}/banners`, this.banners)
    },

    customHref (banner) {
      if(!banner) return '';

      return !(banner.bnrLinkUrl || '' ).trim() || banner.bnrLinkUrl == '#' ? 'javascript:;': banner.bnrLinkUrl;
    },

    customCursorStyle (banner) {
      if(!banner) return 'pointer';

      return {cursor : !(banner.bnrLinkUrl || '' ).trim() || banner.bnrLinkUrl == '#' ? 'default' : 'pointer'};
    },

  }

};
</script>
