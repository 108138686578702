<template>
  <div class="mo_visual"
      style="background-size: cover;"
      >
    <div style="aspect-ratio: 4/5;">
      <client-only>
        <swiper
          ref="swiperOptions"
          :options="banners.length == 1 ? Object.assign(swiperOptions, { loop:false }) : swiperOptions"
          @slideChange="slideChange"
          >
          <swiper-slide v-for="(banner,bannerIndex) in banners" :key="`banner-${bannerIndex}`">
            <!--a :href="getUrl(banner.bnrLinkUrl)" aria-label="해당 메인배너 링크이동">
              <img :src="getImageUrlRS(banner.bnrMobileImgPath, 600)" alt="모바일버전 메인배너 이미지">
              <div class="vi_txt">
                <p v-if="banner.bnrText" v-html-custom="banner.bnrText"></p>
                <strong v-if="banner.bnrHtml" v-html-custom="banner.bnrHtml"></strong>
              </div>
            </a-->
            <template v-if="banner.bnrMobileVideoUrl">
              <a :href="getUrl(banner.bnrLinkUrl)">
                <div class="top-visual-wrap" style="position: relative;width: 100%;padding-top: 125%;">
                  <video class="top-visual-video" style="display:none;" muted loop autoplay playsinline webkit-playsinline>
                    <source :src="banner.bnrMobileVideoUrl" type="video/mp4" />
                  </video>
                </div>
                <img
                  v-if="banner.bnrMobileImgPath"
                  :src="getImageUrlRS(banner.bnrMobileImgPath, 600)"
                  onerror="this.src='/images/no-img.jpg'"
                  alt="까웨 메인배너 이미지"
                  :loading="bannerIndex > 0 ? 'lazy' : 'eager'"
                  />
                <div class="vi_txt">
                  <div :id="`visual-text-box-anchor-${bannerIndex}`" style="overflow: hidden;">
                    <div data-aos="slide-up" :data-aos-anchor="`#visual-text-box-anchor-${bannerIndex}`" data-aos-delay="0" data-aos-duration="1000">
                      <div class="bn_txt_wrap ttl">
                        <p v-if="banner.bnrText" v-html="banner.bnrText"></p>
                      </div>
                      <div class="bn_txt_wrap sub">
                        <p v-if="banner.bnrHtml" v-html-custom="banner.bnrHtml"></p>
                      </div>
                    </div>
                  </div>
                  <div class="bn_btn_wrap mgt20" id="btn-wrap" :data-aos="aosDataVideo" data-aos-delay="1000" data-aos-duration="500">
                      <a v-if="banner.bnrBtnCnt >= 1" :href="getUrl(banner.bnrBtnUrl1)" class="btn-wh-h48-line" :style="{backgroundColor: banner.bnrBtnBgColor1, borderColor: banner.bnrBtnBgColor1, color: banner.bnrBtnColor1}">{{banner.bnrBtnNm1}}</a>
                      <a v-if="banner.bnrBtnCnt >= 2" :href="getUrl(banner.bnrBtnUrl2)" class="btn-wh-h48-line" :style="{backgroundColor: banner.bnrBtnBgColor2, borderColor: banner.bnrBtnBgColor2, color: banner.bnrBtnColor2}">{{banner.bnrBtnNm2}}</a>
                  </div>
                </div>
              </a>
            </template>

            <template v-else>
              <a :href="getUrl(banner.bnrLinkUrl)">
                <img
                  :src="getImageUrlRS(banner.bnrMobileImgPath, 600)"
                  onerror="this.src='/images/no-img.jpg'"
                  alt="까웨 메인배너 이미지"
                  :loading="bannerIndex > 0 ? 'lazy' : 'eager'"
                  >
                <div class="vi_txt">
                  <div :id="`visual-text-box-anchor-${bannerIndex}`" style="overflow: hidden;">
                    <div data-aos="slide-up" :data-aos-anchor="`#visual-text-box-anchor-${bannerIndex}`" data-aos-delay="0" data-aos-duration="1000">
                      <div class="bn_txt_wrap ttl">
                        <p v-if="banner.bnrText" v-html="banner.bnrText"></p>
                      </div>
                      <div class="bn_txt_wrap sub">
                        <p v-if="banner.bnrHtml" v-html-custom="banner.bnrHtml"></p>
                      </div>
                    </div>
                  </div>

                  <div class="bn_btn_wrap mgt20" id="btn-wrap" data-aos="fade-zoom-in" data-aos-delay="1000" data-aos-duration="500">
                      <a v-if="banner.bnrBtnCnt >= 1" :href="getUrl(banner.bnrBtnUrl1)" class="btn-wh-h48-line" :style="{backgroundColor: banner.bnrBtnBgColor1, borderColor: banner.bnrBtnBgColor1, color: banner.bnrBtnColor1}">{{banner.bnrBtnNm1}}</a>
                      <a v-if="banner.bnrBtnCnt >= 2" :href="getUrl(banner.bnrBtnUrl2)" class="btn-wh-h48-line" :style="{backgroundColor: banner.bnrBtnBgColor2, borderColor: banner.bnrBtnBgColor2, color: banner.bnrBtnColor2}">{{banner.bnrBtnNm2}}</a>
                  </div>
                </div>
              </a>
            </template>

          </swiper-slide>

          <!--<div class="swiper-scrollbar" slot="scrollbar"></div>-->
        </swiper>
      </client-only>
    </div>
    <div class="dot_arrow">
      <div class="pagination_wrap">
        <div class="visual-swiper-button-prev swiper-button-prev"></div>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="visual-swiper-button-next swiper-button-next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import banner from '@/components/main/banner';
import Formatter from "@/assets/mixins/formatter";

export default {
  name: "VisualBanner",
  mixins: [banner, Formatter],

  head() {
    return {
      script: this.headScript,
      /*
      link: this.banners.map((banner) => ({
        rel: 'preload',
        as: 'image',
        href: this.getImageUrlRS(banner.bnrMobileImgPath, 600)
      }))
      */
      link: this.banners && this.banners.length > 0 ? [
        {
          rel: 'preload',
          as: 'image',
          href: this.getImageUrlRS(this.banners[0].bnrMobileImgPath, 600)
        }
      ] : []
    }
  },

  data() {
    return {
      startProgress:false,
      aosDataVideo:"fade-zoom-in",
      aosDataVideoChk: 0,
      swiperOptions: {
        initialSlide: 0,
        spaceBetween: 0,
        loop:true,
        loopedSlides:1,
        speed : 400,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
         //scrollbar: {
         // el: ".swiper-scrollbar",
         // hide: false,
         //},
        navigation: {
          prevEl: ".visual-swiper-button-prev",
          nextEl: ".visual-swiper-button-next",
        },
        pagination: {
            el: '.swiper-pagination',
            //type: 'custom',
            type: 'fraction',
            /*
            renderCustom(swiper, current, total) {
                var customPaginationHtml = "";
                        customPaginationHtml += '<span>'+(current)+'</span>/'+total;
                return customPaginationHtml;
            }
            */

        },
      },
      headScript: [],
    }
  },

  watch: {
    banners : {
      immediate: false,
      handler(value) {
        try {
          this.removeSchemaVisualBanner();

          if(!value && value.length <= 0) return;

          //this.headScript = [];
          let itemListElement = [];

          this.banners.forEach((banner, index) => {
            let listItem = {
              "@type": "ListItem",
              "name": banner.bnrText,
              "image": this.getImageUrl(banner.bnrMobileImgPath),
              "url": this.getUrl(banner.bnrLinkUrl),
              "position": (index+1),
            };
            itemListElement.push(listItem);
          });

          let schemaOrgVisualBanner = {
            "@context":"http://schema.org",
            "@type":"ItemList",
            "itemListElement": itemListElement
          };

          let schemaOrgVisualBannerStr = JSON.stringify(schemaOrgVisualBanner);
          let schemaScript = {
            hid: "schemaOrgVisualBanner"
            , mode: "client"
            , type: "application/ld+json"
            , innerHTML: schemaOrgVisualBannerStr
          };

          this.headScript.push(schemaScript);

        } catch (e){}
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() =>
      this.slideChange()
      , 500);
      try{
        //최초에는 스와이퍼가 없어서 에러 하지만 우리는 이미 있는 스와이퍼를 초기화시켜주기위해서 에러는 무시해도됩니다.
          this.swiper.slideTo(1, 1);
      }catch(e){

      }
    });
  },

  beforeDestroy() {
    this.removeSchemaVisualBanner();
  },

  deactivated() {
    this.removeSchemaVisualBanner();
  },

  computed: {
    swiper() {
      return this.$refs.swiperOptions.$swiper || null
    },
  },

  methods: {
    removeSchemaVisualBanner() {
      try {
        //this.$nuxt.$options.head.script.remove('schemaOrgProduct');
        if(this.$nuxt.$options.head.script){
          for(let i = 0 ; i < this.$nuxt.$options.head.script.length ; i++){
            if(this.$nuxt.$options.head.script[i].hid == 'schemaOrgVisualBanner') {
              this.$nuxt.$options.head.script.splice(i, 1);
              i--;
            }
          }
        }
      } catch (e){}
    },

    async slideChange() {
      /* 기존 Video 코드입니다. */
      try {
        //아이폰 모바일에서 버튼 깜박거려서 야매로 처리좀했습니다 죄송합니다.
        if(this.aosDataVideoChk > 0){
          this.aosDataVideo = "slide-up";
        }
        this.aosDataVideoChk++;
        if(this.swiper?.slides) {

          for(var i = 0 ; i < this.swiper.slides.length ; i++ ){
            let slide = this.swiper.slides[i];
            let videoPrev = slide.querySelector('video');
            let visualWrap = slide.querySelector('.top-visual-wrap');
            if (videoPrev) {
              videoPrev.pause();
              if(slide.querySelector('img')) {
                slide.querySelector('img').style.display = '';
                videoPrev.style.display = 'none';
                visualWrap.style.display = 'none';
              }
            }
          }

        }

        let video = this.swiper.slides[this.swiper.activeIndex].querySelector('video');
        let visualWrap = this.swiper.slides[this.swiper.activeIndex].querySelector('.top-visual-wrap');
        if (video) {
          if(this.swiper.slides[this.swiper.activeIndex].querySelector('img')) {
            this.swiper.slides[this.swiper.activeIndex].querySelector('img').style.display = 'none'
          }
          video.style.display = '';
          visualWrap.style.display = '';
          video.play();
        }
      } catch (e) {}

    },

  }

};
</script>

<style lang="css" scoped>
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
    z-index: 10;
    bottom: 100px;
    height: 2px;
    background: rgba(255, 255, 255, 0.4);
    transform: translateX(-50%);
    left:50%;
    width:calc(100% - 90px);
}
.banner-has-video img {
  z-index: -1;
}
.top-visual-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
</style>
