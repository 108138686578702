<template>
  <div class="main_visual">
    <div class="visual_wrap" style="aspect-ratio : 1920 / 780;">
      <client-only>
        <swiper
          ref="swiperOptions"
          :options="banners.length == 1 ? Object.assign(swiperOptions, { loop:false }) : swiperOptions"
          @slideChange="slideChange"
          style="background-color: black;"
        >
          <swiper-slide
            v-for="(banner, bannerIndex) in banners"
            :key="`banner-${bannerIndex}`"
            :class="{'single-slide': banners.length == 1}"
          >
            <!--a :href="getUrl(banner.bnrLinkUrl)" aria-label="해당 메인배너 링크이동">
              <div class="swiper-slide">
                <img :src="getImageUrlRS(banner.bnrImgPath, 1700)" onerror="this.src='/images/no-img.jpg'" alt="PC버전 메인배너 이미지" />
                <div class="vi_txt">
                  <p v-if="banner.bnrText" v-html-custom="banner.bnrText"></p>
                  <strong v-if="banner.bnrHtml" v-html-custom="banner.bnrHtml"></strong>
                </div>
              </div>
            </a-->
            <template v-if="banner.bnrVideoUrl">
              <a :href="getUrl(banner.bnrLinkUrl)">
                <div class="banner-has-video">
                  <div class="vi_txt video_text" :style="alignStyle[banner.bnrAlignType]">
                    <div :id="`visual-text-box-anchor-${bannerIndex}`" style="overflow: hidden;">
                      <div data-aos="slide-up" :data-aos-anchor="`#visual-text-box-anchor-${bannerIndex}`" data-aos-delay="0" data-aos-duration="1000">
                        <div class="bn_txt_wrap ttl">
                          <p v-if="banner.bnrText" v-html-custom="banner.bnrText"></p>
                        </div>
                        <div class="bn_txt_wrap sub">
                          <p v-if="banner.bnrHtml" v-html-custom="banner.bnrHtml"></p>
                        </div>
                      </div>
                    </div>
                    <div class="bn_btn_wrap mgt20" id="btn-wrap" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-duration="500">
                        <a v-if="banner.bnrBtnCnt >= 1" :href="getUrl(banner.bnrBtnUrl1)" class="btn-wh-h48-line" :style="{backgroundColor: banner.bnrBtnBgColor1, borderColor: banner.bnrBtnBgColor1, color: banner.bnrBtnColor1}">{{banner.bnrBtnNm1}}</a>
                        <a v-if="banner.bnrBtnCnt >= 2" :href="getUrl(banner.bnrBtnUrl2)" class="btn-wh-h48-line" :style="{backgroundColor: banner.bnrBtnBgColor2, borderColor: banner.bnrBtnBgColor2, color: banner.bnrBtnColor2}">{{banner.bnrBtnNm2}}</a>
                    </div>
                  </div>
                  <video muted loop autoplay playsinline webkit-playsinline class="top-visual-video" style="aspect-ratio: 1920 / 780;">
                    <source :src="banner.bnrVideoUrl" type="video/mp4" />
                  </video>
                  <img
                    v-if="banner.bnrImgPath"
                    :src="getImageUrlRS(banner.bnrImgPath, 2560)"
                    class="top-visual-img"
                    onerror="this.src='/images/no-img.jpg'"
                    alt="까웨 메인배너 이미지"
                    style="aspect-ratio: 1920 / 780; object-fit: cover;"
                    :loading="bannerIndex > 0 ? 'lazy' : 'eager'"
                    />
                </div>
              </a>
            </template>

            <template v-else>
              <a :href="getUrl(banner.bnrLinkUrl)" aria-label="해당 메인배너 링크이동">
                <div class="swiper-slide">
                  <img
                    :src="getImageUrlRS(banner.bnrImgPath, 2560)"
                    onerror="this.src='/images/no-img.jpg'"
                    alt="까웨 메인배너 이미지"
                    style="aspect-ratio: 1920 / 780; object-fit: cover;"
                    :class="{'faid-in' : bannerIndex == 0}"
                    :loading="bannerIndex > 0 ? 'lazy' : 'eager'"
                    />
                  <div class="vi_txt" :style="alignStyle[banner.bnrAlignType]">
                    <div :id="`visual-text-box-anchor-${bannerIndex}`" style="overflow: hidden;">
                      <div data-aos="slide-up" :data-aos-anchor="`#visual-text-box-anchor-${bannerIndex}`" data-aos-delay="0" data-aos-duration="1000">
                        <div class="bn_txt_wrap ttl">
                          <p v-if="banner.bnrText" v-html-custom="banner.bnrText"></p>
                        </div>
                        <div  class="bn_txt_wrap sub">
                          <p v-if="banner.bnrHtml" v-html-custom="banner.bnrHtml"></p>
                        </div>
                      </div>
                    </div>
                    <div class="bn_btn_wrap mgt20" id="btn-wrap" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-duration="500">
                        <a v-if="banner.bnrBtnCnt >= 1" :href="getUrl(banner.bnrBtnUrl1)" class="btn-wh-h48-line" :style="{backgroundColor: banner.bnrBtnBgColor1, borderColor: banner.bnrBtnBgColor1, color: banner.bnrBtnColor1}">{{banner.bnrBtnNm1}}</a>
                        <a v-if="banner.bnrBtnCnt >= 2" :href="getUrl(banner.bnrBtnUrl2)" class="btn-wh-h48-line" :style="{backgroundColor: banner.bnrBtnBgColor2, borderColor: banner.bnrBtnBgColor2, color: banner.bnrBtnColor2}">{{banner.bnrBtnNm2}}</a>
                    </div>
                  </div>
                </div>
              </a>
            </template>

          </swiper-slide>

        </swiper>
      </client-only>
      </div>
      <div class="dot_arrow">
        <div class="pagination_wrap">
          <div class="visual-swiper-button-prev swiper-button-prev"></div>
          <div slot="pagination" class="swiper-pagination visual-swiper-pagination"></div>
          <div class="visual-swiper-button-next swiper-button-next"></div>
        </div>
      </div>
    </div>
</template>

<script>
import banner from '@/components/main/banner';
import Formatter from "@/assets/mixins/formatter";

export default {
  name: "VisualBanner",
  mixins: [banner, Formatter],

  head() {
    return {
      script: this.headScript,
      /*
      link: this.banners.map((banner) => ({
        rel: 'preload',
        as: 'image',
        href: this.getImageUrlRS(banner.bnrImgPath, 1700)
      }))
      */
     link: this.banners && this.banners.length > 0 ? [
        {
          rel: 'preload',
          as: 'image',
          href: this.getImageUrlRS(this.banners[0].bnrImgPath, 2560)
        }
      ] : []
    }
  },

  data() {
    return {
      swiperOptions: {
        spaceBetween: 0,
        slidesPerView: 1,
        speed : 1000,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        centeredSlides: true,
        loop:true,
        pagination: {
          type: 'fraction',
          el: '.visual-swiper-pagination',
          clickable: true,
        },
        navigation: {
          prevEl: ".visual-swiper-button-prev",
          nextEl: ".visual-swiper-button-next",
        },
        initialSlide: this.$route.meta.initialSlide || 0,
      },
      headScript: [],
      alignStyle: {
        'C': {

        },
        'B': {
          'bottom': '15%'
          , 'top': 'inherit !important'
          , 'transform': 'translate(-50%, 0%) !important'
          , 'text-align': 'center'
        },
      
      },
    }
  },

  watch: {
    banners : {
      immediate: false,
      handler(value) {
        try {
          this.removeSchemaVisualBanner();

          if(!value && value.length <= 0) return;

          //this.headScript = [];
          let itemListElement = [];

          this.banners.forEach((banner, index) => {
            if(banner.bnrImgPath) {
              let listItem = {
                "@type": "ListItem",
                "name": banner.bnrText,
                "image": this.getImageUrl(banner.bnrImgPath),
                "url": this.getUrl(banner.bnrLinkUrl),
                "position": (index+1),
              };
              itemListElement.push(listItem);
            }
          });

          let schemaOrgVisualBanner = {
            "@context":"http://schema.org",
            "@type":"ItemList",
            "itemListElement": itemListElement
          };

          let schemaOrgVisualBannerStr = JSON.stringify(schemaOrgVisualBanner);
          let schemaScript = {
            hid: "schemaOrgVisualBanner"
            , mode: "client"
            , type: "application/ld+json"
            , innerHTML: schemaOrgVisualBannerStr
          };

          this.headScript.push(schemaScript);

        } catch (e){}
      }
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.slideChange();
    });
  },

  beforeDestroy() {
    this.removeSchemaVisualBanner();
  },

  deactivated() {
    this.removeSchemaVisualBanner();
  },

  computed: {
    swiper() {
      return this.$refs.swiperOptions.$swiper || null
    },
  },

  methods: {
    removeSchemaVisualBanner() {
      try {
        //this.$nuxt.$options.head.script.remove('schemaOrgProduct');
        if(this.$nuxt.$options.head.script){
          for(let i = 0 ; i < this.$nuxt.$options.head.script.length ; i++){
            if(this.$nuxt.$options.head.script[i].hid == 'schemaOrgVisualBanner') {
              this.$nuxt.$options.head.script.splice(i, 1);
              i--;
            }
          }
        }
      } catch (e){}
    },

    async slideChange() {
      /* 기존 Video 코드입니다. */
      try {
        if(this.swiper?.slides) {

          for(var i = 0 ; i < this.swiper.slides.length ; i++ ){
            let slide = this.swiper.slides[i];
            let videoPrev = slide.querySelector('video');
            if (videoPrev) {
              videoPrev.pause();
              if(slide.querySelector('img')) {
                slide.querySelector('img').style.display = '';
                videoPrev.style.display = 'none';
              }
            }
          }

        }

        let video = this.swiper.slides[this.swiper.activeIndex].querySelector('video')
        if (video) {
          if(this.swiper.slides[this.swiper.activeIndex].querySelector('img')) {
            this.swiper.slides[this.swiper.activeIndex].querySelector('img').style.display = 'none'
          }
          video.style.display = '';
          video.play();
        }

      } catch (e) {}

    },
  }

};
</script>

<style>
.swiper-pagination-bullet{border-radius:0 !important;}
.top-visual-img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.banner-has-video{
  position: relative;
  width: 100%;
  height: 100%;
}
.top-visual-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video_text {
  z-index: 1;
}
.banner-has-video img {
  z-index: -1;
}
.banner-has-video video {
  width: 100%;
  height: auto;
}
.main_visual .dot_arrow{
  padding: 7px 0;
}

a {
 -webkit-user-drag: none;
}
</style>
